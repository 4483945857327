import axios from 'axios';
import { challengeSchema, deliverableMappingSchema } from '../types';

const mixin = {
  capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.slice(1);
  },
  formatAmount: (x: number) => {
    if (x) {
      return Number(x).toLocaleString('en-US');
    } else {
      return 0;
    }
  },
  markdownToHtml(markdown: string): string  {
    if (markdown) {
    // Heading conversions
    markdown = markdown.replace(/^# (.*$)/gim, '<h1>$1</h1>');
    markdown = markdown.replace(/^## (.*$)/gim, '<h2>$1</h2>');
    markdown = markdown.replace(/^### (.*$)/gim, '<h3>$1</h3>');

    // Bold conversions
    markdown = markdown.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

    // Italic conversions
    markdown = markdown.replace(/\*(.*?)\*/g, '<em>$1</em>');

    // Underline conversions
    markdown = markdown.replace(/__(.*?)__/g, '<u>$1</u>');

    // Strikethrough conversions
    markdown = markdown.replace(/~~(.*?)~~/g, '<del>$1</del>');

    // Subscript conversions
    markdown = markdown.replace(/~(.*?)~/g, '<sub>$1</sub>');

    // Link conversions
    markdown = markdown.replace(/\[([^\]]+)\]\(([^\)]+)\)/g, '<a href="$2">$1</a>');

    // List conversions
    // Unordered lists
    markdown = markdown.replace(/^- (.*$)/gim, '<ul><li>$1</li></ul>');
    
    // Ordered lists
    markdown = markdown.replace(/^\d+\. (.*$)/gim, '<ol><li>$1</li></ol>');

    // Wrap remaining text in paragraphs
    markdown = markdown.split('\n')
      .filter(line => line.trim() !== '')
      .map(line => `<p>${line}</p>`)
      .join('\n');

    return markdown;
    } else {
      return ''
    }
  }, 
  hideEmail (email: string): string {
    if (email) {
      const [username, domain] = email.split('@');
      const visibleCount = Math.min(2, username.length);
    const hiddenCount = username.length - visibleCount;
      const maskedUsername = username.slice(0, visibleCount) + '*'.repeat(hiddenCount);
      return `${maskedUsername}@${domain}`;
    } else {
      return ''
    }
  },
  setRandomColorArray (initials: string) {
    const colors = ["bg-green-600", "bg-red-600", "bg-blue-600", "bg-green-300",  "bg-red-300", "bg-sky-500", "bg-sky-800", "bg-orange-400", "bg-blue-300", "bg-pink-400", "bg-purple-600", "bg-red-800"	];
    if (!initials) {  
      return colors[0];
    } else {
      let hash = 0;
  
      // Create a hash from the string
      for (let i = 0; i < initials.length; i++) {
        hash = (hash << 5) - hash + initials.charCodeAt(i);
        hash |= 0; // Convert to 32bit integer
      }
    
      // Map the hash to a number between 1 and 12
      const randomNumber = Math.abs(hash) % 11 + 1;
    const randomColor = colors[randomNumber];

      return randomColor;
    }

  },
  getHost() {
    const { protocol, hostname, port } = window.location;
    if (port) {
      return `${protocol}//${hostname}:${port}`;
    }
    return `${protocol}//${hostname}`;
  },
  async fetchJobRoles() {
    try {
      const { status, data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/job-titles`);
      if (status === 200) {
        return data;
      }
    } catch (err: any) {
      const { data } = err?.response || {};;
      return {
        type: "warning",
        messageTitle: "Sorry! 🫠",
        messageBody: data?.message || "Something went wrong",
      };
    }
  },
  async uploadFileToCloudinary(
    userFile: File | null,
    fileType: 'image' | 'raw' = 'image'
  ): Promise<string | null> {
    if (!userFile) {
      return null;
    }
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME || '';
    const unsignedUploadPreset =
      process.env.REACT_APP_CLOUDINARY_UNSIGNED_UPLOAD_PRESET || '';
    const url = `https://api.cloudinary.com/v1_1/${cloudName}/${fileType}/upload`;
    const formdata = new FormData();
    formdata.append('file', userFile);
    formdata.append('upload_preset', unsignedUploadPreset);
    try {
      const { data } = await axios.post(url, formdata);
      return data?.secure_url;
    } catch (e) {
      return null;
    }
  },
  getRandomIntInclusive (min: number, max: number): number {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  getDeliverableMappings: (challenge: challengeSchema | undefined) => {
    const mapping: deliverableMappingSchema[] = [];
  
    if (challenge && challenge?.deliverables) {
      challenge.deliverables.forEach(deliverable => {
        const existingSkillIndex = mapping.findIndex(item => item.id === deliverable?.skill?.id);
  
        if (existingSkillIndex !== -1) {
          const existingSkill = mapping[existingSkillIndex];
          const existingDeliverableIndex = existingSkill.content.findIndex(item => item.title.toLowerCase() === deliverable.title.toLowerCase());
  
          if (existingDeliverableIndex === -1) {
            existingSkill.content.push({ title: deliverable.title, content: deliverable.description });
          }
        } else {
          mapping.push({
            id: deliverable?.skill?.id || '',
            title: deliverable?.skill?.name || '',
            content: [{ title: deliverable.title, content: deliverable.description }]
          });
        }
      });
    }
    return mapping;
  },
  formatDate(dateString: string) {
    // from 2015-02-02 to 2024-02-02T09:37:16.164Z

    // Create a Date object from the dateString
    const date = new Date(dateString);

    // Get the parts of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const milliseconds = String(date.getMilliseconds()).padStart(3, '0');

    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;

    return formattedDate;
  },
  extractDate(dateTimeString: string) {
    // from 2024-02-02T09:37:16.164Z to 2015-02-02
    // Create a Date object from the dateTimeString
    const date = new Date(dateTimeString);

    // Get the parts of the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, '0');

    // Create the formatted date string
    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  },
  simplifyDate(dateTimeString: string | Date | null) {
    // from 2024-02-02T09:37:16.164Z to Feb, 2024
    if (dateTimeString) {
      const date = new Date(dateTimeString);
      interface DateFormatOptions {
        month: 'short';
        year: 'numeric';
      }
      const options: DateFormatOptions = { month: 'short', year: 'numeric' };
      return date.toLocaleDateString('en-US', options);
    } else {
      return '';
    }
  },
  getElapsedTime(dateString: string): string {
    const date = new Date(dateString);
    const timeDiff = new Date().getTime() - date.getTime();
    const minute = 60 * 1000;
    const hour = 60 * minute;
    const day = 24 * hour;
    const week = 7 * day;
    let elapsedTime: string;
    if (timeDiff >= week) {
      elapsedTime = `${Math.floor(timeDiff / week)} week(s)`;
    } else if (timeDiff >= day) {
      elapsedTime = `${Math.floor(timeDiff / day)} day(s)`;
    } else if (timeDiff >= hour) {
      elapsedTime = `${Math.floor(timeDiff / hour)} hour(s)`;
    } else if (timeDiff >= minute) {
      elapsedTime = `${Math.floor(timeDiff / minute)} minute(s)`;
    } else {
      elapsedTime = `${Math.floor(timeDiff / 1000)} seconds(s)`;
    }
    return elapsedTime;
  },
  async fetchSkills() {
    try {
      const { status, data } = await axios.get(`${process.env.REACT_APP_ROOT_URL}/skills`);
      if (status === 200) {
        return data;
      }
    } catch (err: any) {
      const { data } = err?.response || {};;
      return {
        type: "warning",
        messageTitle: "Sorry! 🫠",
        messageBody: data?.message || "Something went wrong",
      };
    }
  },
};

export default mixin;
